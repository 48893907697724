import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexPersistence = new VuexPersistence({
  key: 'seasons',
  storage: window.localStorage
})

export default createStore({
  state: {
    language: null,
    year: '',
    calendar: [
      {idSeason:1, begin:'', end:''},
      {idSeason:2, begin:'', end:''},
      {idSeason:3, begin:'', end:''},
      {idSeason:4, begin:'', end:''},
      {idSeason:5, begin:'', end:''},
      {idSeason:6, begin:'', end:''},
      {idSeason:7, begin:'', end:''},
      {idSeason:8, begin:'', end:''},
      {idSeason:9, begin:'', end:''},
      {idSeason:10, begin:'', end:''},
      {idSeason:11, begin:'', end:''},
      {idSeason:12, begin:'', end:''},
      {idSeason:13, begin:'', end:''},
      {idSeason:14, begin:'', end:''},
      {idSeason:15, begin:'', end:''},
      {idSeason:16, begin:'', end:''},
      {idSeason:17, begin:'', end:''},
      {idSeason:18, begin:'', end:''},
      {idSeason:19, begin:'', end:''},
      {idSeason:20, begin:'', end:''},
      {idSeason:21, begin:'', end:''},
      {idSeason:22, begin:'', end:''},
      {idSeason:23, begin:'', end:''},
      {idSeason:24, begin:'', end:''},
      {idSeason:25, begin:'', end:''},
      {idSeason:26, begin:'', end:''},
      {idSeason:27, begin:'', end:''},
      {idSeason:28, begin:'', end:''},
      {idSeason:29, begin:'', end:''},
      {idSeason:30, begin:'', end:''},
      {idSeason:31, begin:'', end:''},
      {idSeason:32, begin:'', end:''},
      {idSeason:33, begin:'', end:''},
      {idSeason:34, begin:'', end:''},
      {idSeason:35, begin:'', end:''},
      {idSeason:36, begin:'', end:''},
      {idSeason:37, begin:'', end:''},
      {idSeason:38, begin:'', end:''},
      {idSeason:39, begin:'', end:''},
      {idSeason:40, begin:'', end:''},
      {idSeason:41, begin:'', end:''},
      {idSeason:42, begin:'', end:''},
      {idSeason:43, begin:'', end:''},
      {idSeason:44, begin:'', end:''},
      {idSeason:45, begin:'', end:''},
      {idSeason:46, begin:'', end:''},
      {idSeason:47, begin:'', end:''},
      {idSeason:48, begin:'', end:''},
      {idSeason:49, begin:'', end:''},
      {idSeason:50, begin:'', end:''},
      {idSeason:51, begin:'', end:''},
      {idSeason:52, begin:'', end:''},
      {idSeason:53, begin:'', end:''},
      {idSeason:54, begin:'', end:''},
      {idSeason:55, begin:'', end:''},
      {idSeason:56, begin:'', end:''},
      {idSeason:57, begin:'', end:''},
      {idSeason:58, begin:'', end:''},
      {idSeason:59, begin:'', end:''},
      {idSeason:60, begin:'', end:''},
      {idSeason:61, begin:'', end:''},
      {idSeason:62, begin:'', end:''},
      {idSeason:63, begin:'', end:''},
      {idSeason:64, begin:'', end:''},
      {idSeason:65, begin:'', end:''},
      {idSeason:66, begin:'', end:''},
      {idSeason:67, begin:'', end:''},
      {idSeason:68, begin:'', end:''},
      {idSeason:69, begin:'', end:''},
      {idSeason:70, begin:'', end:''},
      {idSeason:71, begin:'', end:''},
      {idSeason:72, begin:'', end:''},
    ],
    formula: [
      {idSeason24:1, month:'02',season24:"立春", constD:4.8693, constA:0.242713, yMinus:1},
      {idSeason24:4, month:'02',season24:"雨水", constD:19.7062, constA:0.242627, yMinus:1},
      {idSeason24:7, month:'03',season24:"啓蟄", constD:6.3968, constA:0.242512, yMinus:0},
      {idSeason24:10, month:'03',season24:"春分", constD:21.4471, constA:0.242377, yMinus:0},
      {idSeason24:13, month:'04',season24:"清明", constD:5.628, constA:0.242231, yMinus:0},
      {idSeason24:16, month:'04',season24:"穀雨", constD:20.9375, constA:0.242083, yMinus:0},
      {idSeason24:19, month:'05',season24:"立夏", constD:6.3771, constA:0.241945, yMinus:0},
      {idSeason24:22, month:'05',season24:"小満", constD:21.93, constA:0.241825, yMinus:0},
      {idSeason24:25, month:'06',season24:"芒種", constD:6.5733, constA:0.241731, yMinus:0},
      {idSeason24:28, month:'06',season24:"夏至", constD:22.2747, constA:0.241669, yMinus:0},
      {idSeason24:31, month:'07',season24:"小暑", constD:8.0091, constA:0.241642, yMinus:0},
      {idSeason24:34, month:'07',season24:"大暑", constD:23.7317, constA:0.241654, yMinus:0},
      {idSeason24:37, month:'08',season24:"立秋", constD:8.4102, constA:0.241703, yMinus:0},
      {idSeason24:40, month:'08',season24:"処暑", constD:24.0125, constA:0.241786, yMinus:0},
      {idSeason24:43, month:'09',season24:"白露", constD:8.5186, constA:0.241898, yMinus:0},
      {idSeason24:46, month:'09',season24:"秋分", constD:23.8896, constA:0.242032, yMinus:0},
      {idSeason24:49, month:'10',season24:"寒露", constD:9.1414, constA:0.242179, yMinus:0},
      {idSeason24:52, month:'10',season24:"霜降", constD:24.2487, constA:0.242328, yMinus:0},
      {idSeason24:55, month:'11',season24:"立冬", constD:8.2396, constA:0.242469, yMinus:0},
      {idSeason24:58, month:'11',season24:"小雪", constD:23.1189, constA:0.242592, yMinus:0},
      {idSeason24:61, month:'12',season24:"大雪", constD:7.9152, constA:0.242689, yMinus:0},
      {idSeason24:64, month:'12',season24:"冬至", constD:22.6587, constA:0.242752, yMinus:0},
      {idSeason24:67, month:'01',season24:"小寒", constD:6.3811, constA:0.242778, yMinus:1},
      {idSeason24:70, month:'01',season24:"大寒", constD:21.1046, constA:0.242765, yMinus:1}
    ],
    defaultCalendar: [
      {idSeason:1, begin:'02-03', end:'02-07' },
      {idSeason:2, begin:'02-08', end:'02-12' },
      {idSeason:3, begin:'02-13', end:'02-17' },
      {idSeason:4, begin:'02-18', end:'02-22' },
      {idSeason:5, begin:'02-23', end:'02-29' },
      {idSeason:6, begin:'03-01', end:'03-04' },
      {idSeason:7, begin:'03-05', end:'03-09' },
      {idSeason:8, begin:'03-10', end:'03-14' },
      {idSeason:9, begin:'03-15', end:'03-19' },
      {idSeason:10, begin:'03-20', end:'03-24' },
      {idSeason:11, begin:'03-25', end:'03-29' },
      {idSeason:12, begin:'03-30', end:'04-03' },
      {idSeason:13, begin:'04-04', end:'04-08' },
      {idSeason:14, begin:'04-09', end:'01-13' },
      {idSeason:15, begin:'04-14', end:'04-19' },
      {idSeason:16, begin:'04-20', end:'04-24' },
      {idSeason:17, begin:'04-25', end:'04-29' },
      {idSeason:18, begin:'04-30', end:'05-04' },
      {idSeason:19, begin:'05-05', end:'05-09' },
      {idSeason:20, begin:'05-10', end:'05-14' },
      {idSeason:21, begin:'05-15', end:'05-20' },
      {idSeason:22, begin:'05-21', end:'05-25' },
      {idSeason:23, begin:'05-26', end:'05-30' },
      {idSeason:24, begin:'05-31', end:'06-04' },
      {idSeason:25, begin:'06-05', end:'06-09' },
      {idSeason:26, begin:'06-10', end:'06-14' },
      {idSeason:27, begin:'06-15', end:'06-20' },
      {idSeason:28, begin:'06-21', end:'06-25' },
      {idSeason:29, begin:'06-26', end:'06-30' },
      {idSeason:30, begin:'07-01', end:'07-06' },
      {idSeason:31, begin:'07-07', end:'07-11' },
      {idSeason:32, begin:'07-12', end:'07-16' },
      {idSeason:33, begin:'07-17', end:'07-21' },
      {idSeason:34, begin:'07-22', end:'07-26' },
      {idSeason:35, begin:'07-27', end:'07-31' },
      {idSeason:36, begin:'08-01', end:'08-06' },
      {idSeason:37, begin:'08-07', end:'08-11' },
      {idSeason:38, begin:'08-12', end:'08-16' },
      {idSeason:39, begin:'08-17', end:'08-22' },
      {idSeason:40, begin:'08-23', end:'08-27' },
      {idSeason:41, begin:'08-28', end:'09-01' },
      {idSeason:42, begin:'09-02', end:'09-07' },
      {idSeason:43, begin:'09-07', end:'09-11' },
      {idSeason:44, begin:'09-12', end:'09-16' },
      {idSeason:45, begin:'09-17', end:'09-22' },
      {idSeason:46, begin:'09-23', end:'09-27' },
      {idSeason:47, begin:'09-28', end:'10-02' },
      {idSeason:48, begin:'10-03', end:'10-07' },
      {idSeason:49, begin:'10-08', end:'10-12' },
      {idSeason:50, begin:'10-13', end:'10-17' },
      {idSeason:51, begin:'10-18', end:'10-22' },
      {idSeason:52, begin:'10-23', end:'10-27' },
      {idSeason:53, begin:'10-28', end:'11-01' },
      {idSeason:54, begin:'11-02', end:'11-06' },
      {idSeason:55, begin:'11-07', end:'11-11' },
      {idSeason:56, begin:'11-12', end:'11-16' },
      {idSeason:57, begin:'11-17', end:'11-21' },
      {idSeason:58, begin:'11-22', end:'11-26' },
      {idSeason:59, begin:'11-27', end:'12-01' },
      {idSeason:60, begin:'12-02', end:'12-06' },
      {idSeason:61, begin:'12-07', end:'12-11' },
      {idSeason:62, begin:'12-12', end:'12-16' },
      {idSeason:63, begin:'12-17', end:'12-21' },
      {idSeason:64, begin:'12-22', end:'12-26' },
      {idSeason:65, begin:'12-27', end:'12-31' },
      {idSeason:66, begin:'01-01', end:'01-04' },
      {idSeason:67, begin:'01-05', end:'01-09' },
      {idSeason:68, begin:'01-10', end:'01-14' },
      {idSeason:69, begin:'01-15', end:'01-19' },
      {idSeason:70, begin:'01-20', end:'01-24' },
      {idSeason:71, begin:'01-25', end:'01-29' },
      {idSeason:72, begin:'01-30', end:'02-02' },
    ],
    season: [
      {idSeason:1, season4:"春", season4EN:"Spring", season24:"立春", season24JP:"りっしゅん", season24EN:"Risshun",kanji:"東風解凍", kana:"はるかぜこおりをとく", furigana:"Harukaze kori wo toku", japanese:"東風が厚い氷を解かし始める", french:"Le vent d'est fait fondre les glaces", english:"East wind melts the ice", author:"Utagawa Kunisada", authorJP:"歌川國貞", year:"1830"},
      {idSeason:2, season4:"春", season4EN:"Spring", season24:"立春", season24JP:"りっしゅん", season24EN:"Risshun",kanji:"黄鶯睍睆", kana:"うぐいすなく", furigana:"Uguisu naku", japanese:"鶯（ウグイス）が山里で鳴き始める", french:"Le rossignol commence à chanter", english:"Bush warblers start singing in the mountains", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"~19th"},
      {idSeason:3, season4:"春", season4EN:"Spring", season24:"立春", season24JP:"りっしゅん", season24EN:"Risshun",kanji:"魚上氷", kana:"うおこおりをいずる", furigana:"Uo kori wo izuru", japanese:"割れた氷の間から魚が飛び出る", french:"Dans les rivières en dégel, les poissons réapparaissent", english:"Fish emerge from the ice", author:"Utagawa Kuniyoshi", authorJP:"歌川国芳", year:"1840"},
      {idSeason:4, season4:"春", season4EN:"Spring", season24:"雨水", season24JP:"うすい", season24EN:"Usui",kanji:"土脉潤起", kana:"つちのしょううるおいおこる", furigana:"Tsuchi no sho uruoi okoru", japanese:"雨が降って土が湿り気を含む", french:"La terre se nourrit de l'eau des pluies", english:"Rain moistens the soil", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1833-1834"},
      {idSeason:5, season4:"春", season4EN:"Spring", season24:"雨水", season24JP:"うすい", season24EN:"Usui",kanji:"霞始靆", kana:"かすみはじめてたなびく", furigana:"Kasumi hajimete tanabiku", japanese:"霞（かすみ）がたなびき始める", french:"Premières nappes de brouillard", english:"Mist starts to linger", author:"Katsushika Hokusai", authorJP:"葛飾北斎", year:"1831-1834"},
      {idSeason:6, season4:"春", season4EN:"Spring", season24:"雨水", season24JP:"うすい", season24EN:"Usui",kanji:"草木萠動", kana:"そうもくめばえいずる", furigana:"Somoku mebaeizuru", japanese:"草木が芽吹き始める", french:"Apparition des premiers bourgeons", english:"Grass sprouts, trees bud", author:"Utagawa Kuniyoshi", authorJP:"歌川国芳", year:"1843"},
      {idSeason:7, season4:"春", season4EN:"Spring", season24:"啓蟄", season24JP:"けいちつ", season24EN:"Keichitsu",kanji:"蟄虫啓戸", kana:"すごもりむしとをひらく", furigana:"Sugomorimushi to wo hiraku", japanese:"冬籠りの虫が出て来る", french:"La terre se réchauffe et les insectes sortent de leur hibernation", english:"Hibernating insects surface", author:"Kitagawa Utamaro", authorJP:"喜多川歌麿", year:"1788"},
      {idSeason:8, season4:"春", season4EN:"Spring", season24:"啓蟄", season24JP:"けいちつ", season24EN:"Keichitsu",kanji:"桃始笑", kana:"ももはじめてさく", furigana:"Momo hajimete saku", japanese:"桃の花が咲き始める", french:"Floraison des fleurs de pêchers", english:"First peach blossoms", author:"Nakayama Sûgakudô", authorJP:"中山嵩岳堂", year:"1859"},
      {idSeason:9, season4:"春", season4EN:"Spring", season24:"啓蟄", season24JP:"けいちつ", season24EN:"Keichitsu",kanji:"菜虫化蝶", kana:"なむしちょうとなる", furigana:"Namushi cho to naru", japanese:"青虫が羽化して紋白蝶（モンシロチョウ）になる", french:"Les chenilles se font papillons", english:"Caterpillars become butterflies", author:"Kubo Shunman", authorJP:"窪俊満", year:"~19th"},
      {idSeason:10, season4:"春", season4EN:"Spring", season24:"春分", season24JP:"しゅんぶん", season24EN:"Shunbun",kanji:"雀始巣", kana:"すずめはじめてすくう", furigana:"Suzume hajimete sukuu", japanese:"雀（スズメ）が巣を構え始める", french:"Les moineaux commencent à construire leur nid", english:"Sparrows start to nest", author:"Shoson Ohara", authorJP:"小原祥邨", year:"1910"},
      {idSeason:11, season4:"春", season4EN:"Spring", season24:"春分", season24JP:"しゅんぶん", season24EN:"Shunbun",kanji:"桜始開", kana:"さくらはじめてひらく", furigana:"Sakura hajimete hiraku", japanese:"桜の花が咲き始める", french:"Floraison des fleurs de cerisier", english:"First cherry blossoms", author:"Katsushika Hokusai", authorJP:"葛飾北斎", year:"1830"},
      {idSeason:12, season4:"春", season4EN:"Spring", season24:"春分", season24JP:"しゅんぶん", season24EN:"Shunbun",kanji:"雷乃発声", kana:"かみなりすなわちこえをはっす", furigana:"Kaminari sunawachi koe wo hassu", japanese:"遠くで雷の音がし始める", french:"Le tonnerre chante au lointain", english:"Distant thunder", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1855"},
      {idSeason:13, season4:"春", season4EN:"Spring", season24:"清明", season24JP:"せいめい", season24EN:"Seimei",kanji:"玄鳥至", kana:"つばめきたる", furigana:"Tsubame kitaru", japanese:"燕（ツバメ）が南からやって来る", french:"Les hirondelles reviennent du sud", english:"Swallows return", author:"Teisai Hokuba", authorJP:"蹄斎北馬", year:"~19th"},
      {idSeason:14, season4:"春", season4EN:"Spring", season24:"清明", season24JP:"せいめい", season24EN:"Seimei",kanji:"鴻雁北", kana:"こうがんきたへかえる", furigana:"Kogan kitae kaeru", japanese:"雁（ガン）が北へ渡って行く", french:"Les oies sauvages repartent vers le Nord", english:"Wild geese fly north", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1837"},
      {idSeason:15, season4:"春", season4EN:"Spring", season24:"清明", season24JP:"せいめい", season24EN:"Seimei",kanji:"虹始見", kana:"にじはじめてあらわる", furigana:"Niji hajimete arawaru", japanese:"雨の後に虹が出始める", french:"Premiers arcs-en-ciel après la pluie", english:"First rainbows", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1832"},
      {idSeason:16, season4:"春", season4EN:"Spring", season24:"穀雨", season24JP:"こくう", season24EN:"Kokuu",kanji:"葭始生", kana:"あしはじめてしょうず", furigana:"Ashi hajimete shozu", japanese:"葦（アシ）が芽を吹き始める", french:"Les premiers roseaux bourgeonnent", english:"First reeds sprout", author:"Matsumura Keibun", authorJP:"松村景文", year:"1892"},
      {idSeason:17, season4:"春", season4EN:"Spring", season24:"穀雨", season24JP:"こくう", season24EN:"Kokuu",kanji:"霜止出苗", kana:"しもやんでなえいづる", furigana:"Shimo yande nae izuru", japanese:"霜が終り稲の苗が生長する", french:"Le givre fond et les plants de riz commencent à pousser", english:"Last frost, rice seedlings grow", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1853"},
      {idSeason:18, season4:"春", season4EN:"Spring", season24:"穀雨", season24JP:"こくう", season24EN:"Kokuu",kanji:"牡丹華", kana:"ぼたんはなさく", furigana:"Botan hana saku", japanese:"牡丹（ボタン）の花が咲く", french:"Floraison des pivoines", english:"Peonies bloom", author:"Kawarazaki Shodo", authorJP:"河原崎書堂", year:"1950"},
      {idSeason:19, season4:"夏", season4EN:"Summer", season24:"立夏", season24JP:"りっか", season24EN:"Rikka",kanji:"蛙始鳴", kana:"かわずはじめてなく", furigana:"Kawazu hajimete naku", japanese:"蛙が鳴き始める", french:"Premiers chants de grenouilles", english:"Frogs start singing", author:"Adachi Ginko", authorJP:"安達 吟光", year:"1885"},
      {idSeason:20, season4:"夏", season4EN:"Summer", season24:"立夏", season24JP:"りっか", season24EN:"Rikka",kanji:"蚯蚓出", kana:"みみずいづる", furigana:"Mimizu izuru", japanese:"蚯蚓（ミミズ）が地上に這出る", french:"Les vers de terre s’activent", english:"Worms surface", author:"Ohara Koson", authorJP:"小原古邨", year:"1920"},
      {idSeason:21, season4:"夏", season4EN:"Summer", season24:"立夏", season24JP:"りっか", season24EN:"Rikka",kanji:"竹笋生", kana:"たけのこしょうず ", furigana:"Takenoko shozu", japanese:"筍が生えて来る", french:"Les pousses de bambous sortent de terre", english:"Bamboo shoots sprout", author:"Utagawa Toyokuni", authorJP:"歌川豊国", year:"1850"},
      {idSeason:22, season4:"夏", season4EN:"Summer", season24:"小満", season24JP:"しょうまん", season24EN:"Shouman",kanji:"蚕起食桑", kana:"かいこおきてくわをはむ", furigana:"Kaiko okite kuwa wo hamu", japanese:"蚕が桑を盛んに食べ始める", french:"Les vers à soie se régalent des feuilles de mûrier", english:"Silkworms start feasting on mulberry leaves", author:"Utagawa Fusatane", authorJP:"歌川房種", year:"1867"},
      {idSeason:23, season4:"夏", season4EN:"Summer", season24:"小満", season24JP:"しょうまん", season24EN:"Shouman",kanji:"紅花栄", kana:"べにばなさかう", furigana:"Benibana sakau", japanese:"紅花（ベニバナ）が盛んに咲く", french:"Floraison des carthames", english:"Safflowers bloom", author:"Ichiyosai Toyokuni", authorJP:"一陽斎豊国", year:"1848"},
      {idSeason:24, season4:"夏", season4EN:"Summer", season24:"小満", season24JP:"しょうまん", season24EN:"Shouman",kanji:"麦秋至", kana:"むぎのときいたる", furigana:"Mugi no toki itaru", japanese:"麦が熟し麦秋（ばくしゅう）となる", french:"Les blés mûrissent", english:"Wheat ripens and is harvested", author:"Hasegawa Konobu", authorJP:"長谷川小信", year:"1900"},
      {idSeason:25, season4:"夏", season4EN:"Summer", season24:"芒種", season24JP:"ぼうしゅ", season24EN:"Boushu",kanji:"蟷螂生", kana:"かまきりしょうず ", furigana:"Kamakiri shozu", japanese:"螳螂（カマキリ）が生まれ出る", french:"Les mantes religieuses sortent de l’œuf", english:"Praying mantises hatch", author:"Ohara Koson", authorJP:"小原古邨", year:"120"},
      {idSeason:26, season4:"夏", season4EN:"Summer", season24:"芒種", season24JP:"ぼうしゅ", season24EN:"Boushu",kanji:"腐草為螢", kana:"くされたるくさほたるとなる", furigana:"Kusaretaru kusa hotaru to naru", japanese:"腐った草が蒸れ蛍になる", french:"Les lucioles se transforment et sortent la nuit", english:"Rotten grass becomes fireflies", author:"Mizuno Toshikata", authorJP:"水野年方", year:"1891"},
      {idSeason:27, season4:"夏", season4EN:"Summer", season24:"芒種", season24JP:"ぼうしゅ", season24EN:"Boushu",kanji:"梅子黄", kana:"うめのみきばむ", furigana:"Ume no mi kibamu", japanese:"梅の実が黄ばんで熟す", french:"Les prunes (ume) jaunissent", english:"Plums turn yellow", author:"N/A", authorJP:"N/A", year:"N/A"},
      {idSeason:28, season4:"夏", season4EN:"Summer", season24:"夏至", season24JP:"げし", season24EN:"Geshi",kanji:"乃東枯", kana:"なつかれくさかるる ", furigana:"Natsukarekusa karuru", japanese:"夏枯草（カコソウ）が枯れる", french:"La brunelle commune commence à faner", english:"Self-heal withers", author:"Shibata Zeshin", authorJP:"柴田是真", year:"1900"},
      {idSeason:29, season4:"夏", season4EN:"Summer", season24:"夏至", season24JP:"げし", season24EN:"Geshi",kanji:"菖蒲華", kana:"あやめはなさく", furigana:"Ayame hana saku", japanese:"あやめの花が咲く", french:"Floraison des iris", english:"Irises bloom", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1857"},
      {idSeason:30, season4:"夏", season4EN:"Summer", season24:"夏至", season24JP:"げし", season24EN:"Geshi",kanji:"半夏生", kana:"はんげしょうず", furigana:"Hange shozu", japanese:"烏柄杓（カラスビシャク）が生える", french:"Les pinellia fleurissent", english:"Crow-dipper sprouts", author:"Sakai Hoitsu", authorJP:"酒井抱一", year:"1820"},
      {idSeason:31, season4:"夏", season4EN:"Summer", season24:"小暑", season24JP:"しょうしょ", season24EN:"Shousho",kanji:"温風至", kana:"あつかぜいたる", furigana:"Atsukaze itaru", japanese:"暖い風が吹いて来る", french:"Le vent apporte la chaleur", english:"Warm winds blow", author:"Utagawa Kunisada", authorJP:"歌川國貞", year:"1830"},
      {idSeason:32, season4:"夏", season4EN:"Summer", season24:"小暑", season24JP:"しょうしょ", season24EN:"Shousho",kanji:"蓮始開", kana:"はすはじめてひらく", furigana:"Hasu hajimete hiraku", japanese:"蓮の花が開き始める", french:"Les fleurs de lotus s'épanouissent", english:"First lotus blossoms", author:"Shoson Ohara", authorJP:"小原祥邨", year:"1920"},
      {idSeason:33, season4:"夏", season4EN:"Summer", season24:"小暑", season24JP:"しょうしょ", season24EN:"Shousho",kanji:"鷹乃学習", kana:"たかすなわちわざをなす", furigana:"Taka sunawachi waza wo nasu", japanese:"鷹（タカ）の幼鳥が飛ぶことを覚える", french:"Les faucons apprennent à voler", english:"Hawks learn to fly", author:"Katsushika Hokusai", authorJP:"葛飾北斎", year:"1841"},
      {idSeason:34, season4:"夏", season4EN:"Summer", season24:"大暑", season24JP:"たいしょ", season24EN:"Taisho",kanji:"桐始結花", kana:"きりはじめてはなをむすぶ", furigana:"Kiri hajimete hana wo musubu", japanese:"桐の花が（来年の）蕾をつける", french:"Les fleurs de paulownia commencent à fleurir", english:"Paulownia trees produce seeds", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1856"},
      {idSeason:35, season4:"夏", season4EN:"Summer", season24:"大暑", season24JP:"たいしょ", season24EN:"Taisho",kanji:"土潤溽暑", kana:"つちうるおうてむしあつし", furigana:"Tsuchi uruote mushi atsushi", japanese:"土が湿って蒸暑くなる", french:"La terre devient moite, l’air s’humidifie", english:"Earth is damp, air is humid", author:"Miyagawa Shuntei", authorJP:"宮川春汀", year:"1896"},
      {idSeason:36, season4:"夏", season4EN:"Summer", season24:"大暑", season24JP:"たいしょ", season24EN:"Taisho",kanji:"大雨時行", kana:"たいうときどきにふる", furigana:"Taiu tokidoki ni furu", japanese:"時として大雨が降る", french:"Pluies abondantes d'été", english:"Great rains sometimes fall", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1835"},
      {idSeason:37, season4:"秋", season4EN:"Autumn", season24:"立秋", season24JP:"りっしゅう", season24EN:"Risshuu",kanji:"涼風至", kana:"すずかぜいたる", furigana:"Suzukaze itaru", japanese:"涼しい風が立ち始める", french:"Légère brise rafraîchissante", english:"Cool winds blow", author:"Toyohara Chikanobu", authorJP:"豊原周延", year:"1897"},
      {idSeason:38, season4:"秋", season4EN:"Autumn", season24:"立秋", season24JP:"りっしゅう", season24EN:"Risshuu",kanji:"寒蝉鳴", kana:"ひぐらしなく", furigana:"Higurashi naku", japanese:"蜩（ヒグラシ）が鳴き始める", french:"Chant nocturne des cigales", english:"Evening cicadas sing", author:"Kitagawa Utamaro", authorJP:"喜多川歌麿", year:"1788"},
      {idSeason:39, season4:"秋", season4EN:"Autumn", season24:"立秋", season24JP:"りっしゅう", season24EN:"Risshuu",kanji:"蒙霧升降", kana:"ふかききりまとう", furigana:"Fukaki kiri matou", japanese:"深い霧が立ち込める", french:"L’épais brouillard descend", english:"Thick fog descends", author:"Katsushika Hokusai", authorJP:"葛飾北斎", year:"1831-1834"},
      {idSeason:40, season4:"秋", season4EN:"Autumn", season24:"処暑", season24JP:"しょしょ", season24EN:"Shosho",kanji:"綿柎開", kana:"わたのはなしべひらく", furigana:"Wata no hanashibe hiraku", japanese:"綿を包む萼（がく）が開く", french:"Les fleurs de coton s'ouvrent", english:"Cotton flowers bloom", author:"Sakai Hoitsu", authorJP:"酒井抱一", year:"1820"},
      {idSeason:41, season4:"秋", season4EN:"Autumn", season24:"処暑", season24JP:"しょしょ", season24EN:"Shosho",kanji:"天地始粛", kana:"てんちはじめてさむし", furigana:"Tenchi hajimete samushi", japanese:"ようやく暑さが鎮まる", french:"La chaleur s'atténue enfin", english:"Heat starts to die down", author:"Hashiguchi Goyo", authorJP:"橋口五葉", year:"1920"},
      {idSeason:42, season4:"秋", season4EN:"Autumn", season24:"処暑", season24JP:"しょしょ", season24EN:"Shosho",kanji:"禾乃登", kana:"こくものすなわちみのる", furigana:"Kokumono sunawachi minoru", japanese:"稲が実る", french:"Le riz arrive à maturité", english:"Rice ripens", author:"Katsushika Hokusai", authorJP:"葛飾北斎", year:"1835"},
      {idSeason:43, season4:"秋", season4EN:"Autumn", season24:"白露", season24JP:"はくろ", season24EN:"Hakuro",kanji:"草露白", kana:"くさのつゆしろし", furigana:"Kusa no tsuyu shiroshi", japanese:"草に降りた露が白く光る", french:"La rosée scintille sur les herbes", english:"Dew glistens white on grass", author:"Shuntei", authorJP:"春汀", year:"1904"},
      {idSeason:44, season4:"秋", season4EN:"Autumn", season24:"白露", season24JP:"はくろ", season24EN:"Hakuro",kanji:"鶺鴒鳴", kana:"せきれいなく", furigana:"Sekirei naku", japanese:"鶺鴒（せきれい）が鳴き始める", french:"Les bergeronnettes commencent à chanter", english:"Wagtails sing", author:"Katsushika Hokusai", authorJP:"葛飾北斎", year:"1834"},
      {idSeason:45, season4:"秋", season4EN:"Autumn", season24:"白露", season24JP:"はくろ", season24EN:"Hakuro",kanji:"玄鳥去", kana:"つばめさる", furigana:"Tsubame saru", japanese:"燕が南へ帰って行く", french:"Les hirondelles repartent vers le sud", english:"Swallows leave", author:"Daiko", authorJP:"大耕", year:"1930"},
      {idSeason:46, season4:"秋", season4EN:"Autumn", season24:"秋分", season24JP:"しゅうぶん", season24EN:"Shuubun",kanji:"雷乃収声", kana:"かみなりすなわちこえをおさむ", furigana:"Kaminari sunawachi koe wo osamu", japanese:"雷が鳴り響かなくなる", french:"Le grondement du tonnerre s'apaise", english:"Thunder ceases", author:"Utagawa Kunisada", authorJP:"歌川國貞", year:"1830"},
      {idSeason:47, season4:"秋", season4EN:"Autumn", season24:"秋分", season24JP:"しゅうぶん", season24EN:"Shuubun",kanji:"蟄虫坏戸", kana:"むしかくれてとをふさぐ", furigana:"Mushi kakurete to wo fusagu", japanese:"虫が土中に掘った穴をふさぐ", french:"Les insectes se cachent pour hiberner", english:"Insects hole up underground", author:"Hosoda Eishi", authorJP:"細田栄之", year:"~17th"},
      {idSeason:48, season4:"秋", season4EN:"Autumn", season24:"秋分", season24JP:"しゅうぶん", season24EN:"Shuubun",kanji:"水始涸", kana:"みずはじめてかる", furigana:"Mizu hajimete karu", japanese:"田畑の水を干し始める", french:"Les rizières sont asséchées", english:"Farmers drain rice fields", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1852"},
      {idSeason:49, season4:"秋", season4EN:"Autumn", season24:"寒露", season24JP:"かんろ", season24EN:"Kanro",kanji:"鴻雁来", kana:"こうがんきたる", furigana:"Kogan kitaru", japanese:"雁（ガン）が飛来し始める", french:"Le retour des oies sauvages", english:"Wild geese return", author:"Rokkaen Yoshiyuki", authorJP:"六花園芳雪", year:"1801"},
      {idSeason:50, season4:"秋", season4EN:"Autumn", season24:"寒露", season24JP:"かんろ", season24EN:"Kanro",kanji:"菊花開", kana:"きくのはなひらく", furigana:"Kiku no hana hiraku", japanese:"菊の花が咲く", french:"Floraison des chrysanthèmes", english:"Chrysanthemums bloom", author:"Toyohara Chikanobu", authorJP:"豊原周延", year:"1888"},
      {idSeason:51, season4:"秋", season4EN:"Autumn", season24:"寒露", season24JP:"かんろ", season24EN:"Kanro",kanji:"蟋蟀在戸", kana:"きりぎりすとにあり", furigana:"Kirigirisu to ni ari", japanese:"蟋蟀（キリギリス）が戸の辺りで鳴く", french:"Les criquets chantent au pas de la porte", english:"Crickets chirp around the door", author:"Kitagawa Utamaro", authorJP:"喜多川歌麿", year:"1788"},
      {idSeason:52, season4:"秋", season4EN:"Autumn", season24:"霜降", season24JP:"そうこう", season24EN:"Soukou",kanji:"霜始降", kana:"しもはじめてふる", furigana:"Shimo hajimete furu", japanese:"霜が降り始める", french:"Premières gelées d’automne", english:"First frost", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1835"},
      {idSeason:53, season4:"秋", season4EN:"Autumn", season24:"霜降", season24JP:"そうこう", season24EN:"Soukou",kanji:"霎時施", kana:"こさめときどきふる", furigana:"Kosame tokidoki furu", japanese:"小雨がしとしと降る", french:"Légères pluies éparses", english:"Light rains sometimes fall", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1840"},
      {idSeason:54, season4:"秋", season4EN:"Autumn", season24:"霜降", season24JP:"そうこう", season24EN:"Soukou",kanji:"楓蔦黄", kana:"もみじつたきばむ", furigana:"Momiji tsuta kibamu", japanese:"もみじや蔦（ツタ）が黄葉する", french:"Les feuilles des érables et le lierre rougissent", english:"Maple leaves and ivy turn yellow", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1853"},
      {idSeason:55, season4:"冬", season4EN:"Winter", season24:"立冬", season24JP:"りっとう", season24EN:"Rittou",kanji:"山茶始開", kana:"つばきはじめてひらく", furigana:"Tsubaki hajimete hiraku", japanese:"山茶花（サザンカ）が咲き始める", french:"Floraison des camélias", english:"Camellias bloom", author:"Tanigami Konan", authorJP:"谷上廣南", year:"1910"},
      {idSeason:56, season4:"冬", season4EN:"Winter", season24:"立冬", season24JP:"りっとう", season24EN:"Rittou",kanji:"地始凍", kana:"ちはじめてこおる", furigana:"Chi hajimete koru", japanese:"大地が凍り始める", french:"Les terres commencent à geler", english:"Land starts to freeze", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1833"},
      {idSeason:57, season4:"冬", season4EN:"Winter", season24:"立冬", season24JP:"りっとう", season24EN:"Rittou",kanji:"金盞香", kana:"きんせんかさく", furigana:"Kinsenka saku", japanese:"水仙の花が咲く", french:"Floraison des jonquilles", english:"Daffodils bloom", author:"Nakayama Sûgakudô", authorJP:"中山嵩岳堂", year:"1859"},
      {idSeason:58, season4:"冬", season4EN:"Winter", season24:"小雪", season24JP:"しょうせつ", season24EN:"Shousetsu",kanji:"虹蔵不見", kana:"にじかくれてみえず", furigana:"Niji kakurete miezu", japanese:"虹を見かけなくなる", french:"Les arcs-en-ciel se cachent", english:"Rainbows hide", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1856"},
      {idSeason:59, season4:"冬", season4EN:"Winter", season24:"小雪", season24JP:"しょうせつ", season24EN:"Shousetsu",kanji:"朔風払葉", kana:"きたかぜこのはをはらう", furigana:"Kitakaze konoha wo harau", japanese:"北風が木の葉を払い除ける", french:"Le vent du Nord emporte avec lui les feuilles des arbres", english:"North wind blows the leaves from the trees", author:"Katsushika Hokusai", authorJP:"葛飾北斎", year:"1830"},
      {idSeason:60, season4:"冬", season4EN:"Winter", season24:"小雪", season24JP:"しょうせつ", season24EN:"Shousetsu",kanji:"橘始黄", kana:"たちばなはじめてきばむ", furigana:"Tachibana hajimete kibamu", japanese:"橘（タチバナ）の実が黄色くなり始める", french:"Les feuilles du mandarinier tachibana jaunissent", english:"Tachibana citrus tree leaves start to turn yellow", author:"Imao Keinen", authorJP:"今尾景年", year:"1892"},
      {idSeason:61, season4:"冬", season4EN:"Winter", season24:"大雪", season24JP:"たいせつ", season24EN:"Taisetsu",kanji:"閉塞成冬", kana:"そらさむくふゆとなる", furigana:"Sora samuku fuyu to naru", japanese:"天地の気が塞がって冬となる", french:"Le froid règne et l'hiver s'installe", english:"Cold sets in, winter begins", author:"Yamamoto Shoun", authorJP:"山本昇雲", year:"1930"},
      {idSeason:62, season4:"冬", season4EN:"Winter", season24:"大雪", season24JP:"たいせつ", season24EN:"Taisetsu",kanji:"熊蟄穴", kana:"くまあなにこもる", furigana:"Kuma ana ni komoru", japanese:"熊が冬眠のために穴に隠れる", french:"Les ours s’en vont hiberner dans leurs tanières", english:"Bears start hibernating in their dens", author:"Utagawa Kuniyoshi", authorJP:"歌川国芳", year:"~19th"},
      {idSeason:63, season4:"冬", season4EN:"Winter", season24:"大雪", season24JP:"たいせつ", season24EN:"Taisetsu",kanji:"鱖魚群", kana:"さけのうおむらがる", furigana:"Sake no uo muragaru", japanese:"鮭が群がり川を上る", french:"Les saumons se rassemblent et remontent le courant", english:"Salmon gather and swim upstream", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1830"},
      {idSeason:64, season4:"冬", season4EN:"Winter", season24:"冬至", season24JP:"とうじ", season24EN:"Touji",kanji:"乃東生", kana:"なつかれくさしょうず ", furigana:"Natsukarekusa shozu", japanese:"夏枯草（カコソウ）が芽を出す", french:"Les pousses de brunelle apparaissent", english:"Self-heal sprouts", author:"N/A", authorJP:"N/A", year:"N/A"},
      {idSeason:65, season4:"冬", season4EN:"Winter", season24:"冬至", season24JP:"とうじ", season24EN:"Touji",kanji:"麋角解", kana:"おおしかのつのおつる", furigana:"Oshika no tsuno otsuru", japanese:"大鹿が角を落とす", french:"Les cerfs perdent leurs bois", english:"Deer shed antlers", author:"Ohara Koson", authorJP:"小原古邨", year:"1915"},
      {idSeason:66, season4:"冬", season4EN:"Winter", season24:"冬至", season24JP:"とうじ", season24EN:"Touji",kanji:"雪下出麦", kana:"ゆきわたりてむぎいづる", furigana:"Yuki watarite mugi izuru", japanese:"雪の下で麦が芽を出す", french:"Sous la neige germe le blé", english:"Wheat sprouts under snow", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1833"},
      {idSeason:67, season4:"冬", season4EN:"Winter", season24:"小寒", season24JP:"しょうかん", season24EN:"Shoukan",kanji:"芹乃栄", kana:"せりすなわちさかう", furigana:"Seri sunawachi sakau", japanese:"芹（セリ）がよく生育する", french:"Les pousses d'oenanthe apparaissent", english:"Parsley flourishes", author:"Sakai Hoitsu", authorJP:"酒井抱一", year:"1820"},
      {idSeason:68, season4:"冬", season4EN:"Winter", season24:"小寒", season24JP:"しょうかん", season24EN:"Shoukan",kanji:"水泉動", kana:"しみずあたたかをふくむ", furigana:"Shimizu atataka wo fukumu", japanese:"地中で凍った泉が動き始める", french:"Dans les sols gelés, la glace commence à fondre", english:"Springs thaw", author:"Katsushika Hokusai", authorJP:"葛飾北斎", year:"1833"},
      {idSeason:69, season4:"冬", season4EN:"Winter", season24:"小寒", season24JP:"しょうかん", season24EN:"Shoukan",kanji:"雉始雊", kana:"きじはじめてなく", furigana:"Kiji hajimete naku", japanese:"雄の雉（キジ）が鳴き始める", french:"Les faisans commencent à chanter", english:"Pheasants start to call", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"~19th"},
      {idSeason:70, season4:"冬", season4EN:"Winter", season24:"大寒", season24JP:"だいかん", season24EN:"Daikan",kanji:"款冬華", kana:"ふきのはなさく", furigana:"Fuki no hana saku", japanese:"蕗の薹（ふきのとう）が蕾を出す", french:"Apparition des premiers bourgeons de pétasites", english:"Butterburs bud", author:"Sakai Hoitsu", authorJP:"酒井抱一", year:"1820"},
      {idSeason:71, season4:"冬", season4EN:"Winter", season24:"大寒", season24JP:"だいかん", season24EN:"Daikan",kanji:"水沢腹堅", kana:"さわみずこおりつめる", furigana:"Sawamizu kori tsumeru", japanese:"沢に氷が厚く張りつめる", french:"La glace s’épaissit sur les ruisseaux", english:"Ice thickens on streams", author:"Utagawa Hiroshige", authorJP:"歌川広重", year:"1843-1847"},
      {idSeason:72, season4:"冬", season4EN:"Winter", season24:"大寒", season24JP:"だいかん", season24EN:"Daikan",kanji:"鶏始乳", kana:"にわとりはじめてとやにつく", furigana:"Niwatori hajimete toya ni tsuku", japanese:"鶏が卵を産み始める", french:"Les poules commencent à couver", english:"Hens start laying eggs", author:"Katsushika Hokusai", authorJP:"葛飾北斎", year:"1830-1833"}
    ]
  },
  getters: {
    seasonList (state) {
      return state.season
    }
  },
  mutations: {
    setLanguage (state, language) {
      state.language = language
    },
    setCalendar (state, year) {
      state.year = year
      var previousDate = ''
      var date = ''
      var newDate = ''
      var beginDay = ''
      var j = 0
      var constD = 0
      var constA = 0
      var yMinus = 0
      for (var i=0; i < state.calendar.length; i++) {
        // Set begin date
        if (j < state.formula.length && state.calendar[i].idSeason == state.formula[j].idSeason24) {
          constD = state.formula[j].constD
          constA = state.formula[j].constA
          yMinus = state.formula[j].yMinus
          beginDay = parseInt(constD + (constA * ((year - yMinus) - 1900)) - parseInt(((year - yMinus) - 1900) / 4, '10'), '10')
          if (beginDay < 10) {
              beginDay = '0' + beginDay
          }
          state.calendar[i].begin = state.formula[j].month + '-' + beginDay
          j = j + 1
          previousDate = state.calendar[i].begin
        } else {
          date = new Date(year + '-' + previousDate)
          date.setDate(date.getDate() + 5)
          newDate = date.toLocaleDateString('sv-SE').substring(5, 11)
          state.calendar[i].begin = newDate
          previousDate = state.calendar[i].begin
        }
      }

      for (var k=0; k < state.calendar.length; k++) {
        // Set end date
        if (k == state.calendar.length - 1) {
          date = new Date(year + '-' + state.calendar[0].begin)
          date.setDate(date.getDate() - 1)
          newDate = date.toLocaleDateString('sv-SE').substring(5, 11)
          state.calendar[k].end = newDate
        } else if (k < state.calendar.length - 1) {
          date = new Date(year + '-' + state.calendar[k+1].begin)
          date.setDate(date.getDate() - 1)
          newDate = date.toLocaleDateString('sv-SE').substring(5, 11)
          state.calendar[k].end = newDate
        } else {
          date = new Date(year + '-' + state.calendar[k].begin)
          date.setDate(date.getDate() + 5)
          newDate = date.toLocaleDateString('sv-SE').substring(5, 11)
          state.calendar[k].end = newDate
        }
//        console.log("[" + k + "] begin: " + state.calendar[k].begin + " end: " + state.calendar[k].end) // TODO
      }

      // Set the end/begining of the year
      state.calendar[64].end = '12-31'
      state.calendar[65].begin = '01-01'
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexPersistence.plugin]
})
