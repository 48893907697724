import { createRouter, createWebHistory } from 'vue-router'
const HomeView = () => import('../views/HomeView.vue')
const WhatIs72Seasons = () => import('../views/72Seasons.vue')
const SeasonView = () => import('../views/SeasonView.vue')
const SearchName = () => import('../views/SearchName.vue')
const SearchDate = () => import('../views/SearchDate.vue')
const SearchUkiyoe = () => import('../views/SearchUkiyoe.vue')
const PrivacyPolicy = () => import('../views/PrivacyPolicy.vue')
import Store from '../store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '浮世絵で見る七十二候',
      metaTags: [
        {
          name: 'description',
          content: '二十四節気七十二候の各候の意味・英訳・フランス語訳を浮世絵ともに紹介。今日の季節や指定した年月日の七十二候も調べられます。'
        },
        {
          property: 'og:description',
          content: '二十四節気七十二候の各候の意味・英訳・フランス語訳を浮世絵ともに紹介。今日の季節や指定した年月日の七十二候も調べられます。'
        }
      ]
    }
  },
  {
    path: '/what-is-72-seasons/',
    name: '72seasons',
    component: WhatIs72Seasons,
    meta: {
      title: '七十二候とは | 浮世絵で見る七十二候',
      metaTags: [
        {
          name: 'description',
          content: '二十四節気をさらに約5日ごとに分け、1年を72等分して自然事象や動植物の変化を短文で表した七十二候の一覧と計算方法です。'
        },
        {
          property: 'og:description',
          content: '二十四節気をさらに約5日ごとに分け、1年を72等分して自然事象や動植物の変化を短文で表した七十二候の一覧と計算方法です。'
        }
      ]
    }
  },
  {
    path: '/season-now/',
    name: 'season',
    component: SeasonView,
    meta: {
      title: '今日は何の季節？ | 浮世絵で見る七十二候',
      metaTags: [
        {
          name: 'description',
          content: '今日の二十四節気（にじゅうしせっき）七十二候（しちじゅうにこう）の意味・英訳・フランス語訳が浮世絵とともに表示されます。'
        },
        {
          property: 'og:description',
          content: '今日の二十四節気（にじゅうしせっき）七十二候（しちじゅうにこう）の意味・英訳・フランス語訳が浮世絵とともに表示されます。'
        }
      ]
    }
  },
  {
    path: '/:season/',
    name: 'seasonItem',
    component: SeasonView,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/search-by-name/',
    name: 'name',
    component: SearchName,
    meta: {
      title: '名前から探す七十二候 | 浮世絵で見る七十二候',
      metaTags: [
        {
          name: 'description',
          content: '七十二候の名称・読み・意味の一覧から、該当の候の日付を自動計算し、英訳・フランス語訳・浮世絵とともに表示します。'
        },
        {
          property: 'og:description',
          content: '七十二候の名称・読み・意味の一覧から、該当の候の日付を自動計算し、英訳・フランス語訳・浮世絵とともに表示します。'
        }
      ]
    }
  },
  {
    path: '/search-by-date/',
    name: 'calendar',
    component: SearchDate,
    meta: {
      title: '日にちから探す七十二候 | 浮世絵で見る七十二候',
      metaTags: [
        {
          name: 'description',
          content: 'カレンダーから指定した日付に該当する七十二候を自動計算し、意味・英訳・フランス語訳・浮世絵とともに表示します。'
        },
        {
          property: 'og:description',
          content: 'カレンダーから指定した日付に該当する七十二候を自動計算し、意味・英訳・フランス語訳・浮世絵とともに表示します。'
        }
      ]
    }
  },
  {
    path: '/search-by-ukiyoe/',
    name: 'ukiyoe',
    component: SearchUkiyoe,
    meta: {
      title: '浮世絵から探す七十二候 | 浮世絵で見る七十二候',
      metaTags: [
        {
          name: 'description',
          content: '「浮世絵で見る七十二候」で使用している浮世絵一覧から、各候の日付・名称・意味・英訳・フランス語訳が表示されます。'
        },
        {
          property: 'og:description',
          content: '「浮世絵で見る七十二候」で使用している浮世絵一覧から、各候の日付・名称・意味・英訳・フランス語訳が表示されます。'
        }
      ]
    }
  },
  {
    path: '/privacy_policy',
    name: 'privacy',
    component: PrivacyPolicy,
    meta: {
      title: 'プライバシーポリシー',
      metaTags: [
        {
          name: 'description',
          content: 'プライバシーポリシー'
        },
        {
          property: 'og:description',
          content: 'プライバシーポリシー'
        }
      ]
    }
  },
//////////////// English pages ////////////////
  {
    path: '/en/',
    name: 'homeEN',
    component: HomeView,
    meta: {
      title: '72 Seasons with Ukiyo-e',
      metaTags: [
        {
          name: 'description',
          content: "Discover Japan's 72 Seasons with Date, Name, Meaning in Japanese/English/French, along with Beautiful Ukiyo-e."
        },
        {
          property: 'og:description',
          content: "Discover Japan's 72 Seasons with Date, Name, Meaning in Japanese/English/French, along with Beautiful Ukiyo-e."
        }
      ]
    }
  },
  {
    path: '/en/what-is-72-seasons/',
    name: '72seasonsEN',
    component: WhatIs72Seasons,
    meta: {
      title: 'What Is 72 Seasons? | 72 Seasons with Ukiyo-e',
      metaTags: [
        {
          name: 'description',
          content: 'List of All 72 Seasons of Japan called 七十二候(Shichijuni Kou). Each season lasts about five days and describes changes in animals, plants, and nature.'
        },
        {
          property: 'og:description',
          content: 'List of All 72 Seasons of Japan called 七十二候(shichijuni kou). Each season lasts about five days and describes changes in animals, plants, and nature.'
        }
      ]
    }
  },
  {
    path: '/en/season-now/',
    name: 'seasonEN',
    component: SeasonView,
    meta: {
      title: 'What Season Are We in Now? | 72 Seasons with Ukiyo-e',
      metaTags: [
        {
          name: 'description',
          content: "Discover Today's 24 Seasons and 72 Seasons with Japanese Name, Reading, Meaning in English and French, accompanied by Beautiful Ukiyo-e."
        },
        {
          property: 'og:description',
          content: "Discover Today's 24 Seasons and 72 Seasons with Japanese Name, Reading, Meaning in English and French, accompanied by Beautiful Ukiyo-e."
        }
      ]
    }
  },
  {
    path: '/en/:season/',
    name: 'seasonItemEN',
    component: SeasonView,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/en/search-by-name/',
    name: 'nameEN',
    component: SearchName,
    meta: {
      title: 'Search 72 Seasons By Name | 72 Seasons with Ukiyo-e',
      metaTags: [
        {
          name: 'description',
          content: 'Find 72 Japanese Seasons from the List of All Seasons with Japanese Names in Kanji, Readings, and English Translations.'
        },
        {
          property: 'og:description',
          content: 'Find 72 Japanese Seasons from the List of All Seasons with Japanese Names in Kanji, Readings, and English Translations.'
        }
      ]
    }
  },
  {
    path: '/en/search-by-date/',
    name: 'calendarEN',
    component: SearchDate,
    meta: {
      title: 'Search 72 Seasons By Date | 72 Seasons with Ukiyo-e',
      metaTags: [
        {
          name: 'description',
          content: 'Find 72 Japanese Seasons correspond to the Specified Date from the Calendar, with Automatically Calculated Period, Name, and Meaning.'
        },
        {
          property: 'og:description',
          content: 'Find 72 Japanese Seasons correspond to the Specified Date from the Calendar, with Automatically Calculated Period, Name, and Meaning.'
        }
      ]
    }
  },
  {
    path: '/en/search-by-ukiyoe/',
    name: 'ukiyoeEN',
    component: SearchUkiyoe,
    meta: {
      title: 'Search 72 Seasons By Ukiyo-e | 72 Seasons with Ukiyo-e',
      metaTags: [
        {
          name: 'description',
          content: 'Find 72 Japanese Seasons from List of Ukiyo-e, with Date, Name, Meaning, English/French Translation, along with Ukiyo-e painter and the year.'
        },
        {
          property: 'og:description',
          content: 'Find 72 Japanese Seasons from List of Ukiyo-e, with Date, Name, Meaning, English/French Translation, along with Ukiyo-e painter and the year.'
        }
      ]
    }
  },
  {
    path: '/en/privacy_policy',
    name: 'privacyEN',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy policy',
      metaTags: [
        {
          name: 'description',
          content: 'Privacy policy'
        },
        {
          property: 'og:description',
          content: 'Privacy policy'
        }
      ]
    }
  },
//////////////// French pages ////////////////
  {
    path: '/fr/',
    name: 'homeFR',
    component: HomeView,
    meta: {
      title: '72 Saisons du Japon',
      metaTags: [
        {
          name: 'description',
          content: 'Découvrez les 72 Saisons du Japon par date, nom, signification en japonais/français/anglais, illustrées par de magnifiques estampes.'
        },
        {
          property: 'og:description',
          content: 'Découvrez les 72 Saisons du Japon par date, nom, signification en japonais/français/anglais, illustrées par de magnifiques estampes.'
        }
      ]
    }
  },
  {
    path: '/fr/what-is-72-seasons/',
    name: '72seasonsFR',
    component: WhatIs72Seasons,
    meta: {
      title: "Qu'est-ce que les 72 Saisons du Japon ? | 72 Saisons du Japon",
      metaTags: [
        {
          name: 'description',
          content: 'Liste des 72 Saisons du Japon. Chaque saison dure environ cinq jours et décrit les changements de la nature, des plantes et chez les animaux.'
        },
        {
          property: 'og:description',
          content: 'Liste des 72 Saisons du Japon. Chaque saison dure environ cinq jours et décrit les changements de la nature, des plantes et chez les animaux.'
        }
      ]
    }
  },
  {
    path: '/fr/season-now/',
    name: 'seasonFR',
    component: SeasonView,
    meta: {
      title: 'Dans quelle saison sommes-nous actuellement ? | 72 Saisons du Japon',
      metaTags: [
        {
          name: 'description',
          content: "Découvrez les 24 Saisons et les 72 Saisons d'aujourd'hui avec le nom japonais, la lecture, la signification en français et en anglais, illustrées par de magnifiques estampes."
        },
        {
          property: 'og:description',
          content: "Découvrez les 24 Saisons et les 72 Saisons d'aujourd'hui avec le nom japonais, la lecture, la signification en français et en anglais, illustrées par de magnifiques estampes."
        }
      ]
    }
  },
  {
    path: '/fr/:season/',
    name: 'seasonItemFR',
    component: SeasonView,
    props: true,
    meta: {
      title: '',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/fr/search-by-name/',
    name: 'nameFR',
    component: SearchName,
    meta: {
      title: 'Rechercher les 72 Saisons par nom | 72 Saisons du Japon',
      metaTags: [
        {
          name: 'description',
          content: 'Retrouvez la liste complète des 72 Saisons japonaises avec le nom japonais en kanji, la lecture et les traductions en français.'
        },
        {
          property: 'og:description',
          content: 'Retrouvez la liste complète des 72 Saisons japonaises avec le nom japonais en kanji, la lecture et les traductions en français.'
        }
      ]
    }
  },
  {
    path: '/fr/search-by-date/',
    name: 'calendarFR',
    component: SearchDate,
    meta: {
      title: 'Rechercher les 72 Saisons par date | 72 Saisons du Japon',
      metaTags: [
        {
          name: 'description',
          content: 'Retrouvez les 72 Saisons japonaises correspondant à la date spécifiée dans le calendrier, avec la période calculée automatiquement, le nom et la signification.'
        },
        {
          property: 'og:description',
          content: 'Retrouvez les 72 Saisons japonaises correspondant à la date spécifiée dans le calendrier, avec la période calculée automatiquement, le nom et la signification.'
        }
      ]
    }
  },
  {
    path: '/fr/search-by-ukiyoe/',
    name: 'ukiyoeFR',
    component: SearchUkiyoe,
    meta: {
      title: 'Rechercher les 72 Saisons par estampe | 72 Saisons du Japon',
      metaTags: [
        {
          name: 'description',
          content: "Retrouvez les 72 Saisons japonaises dans la liste des estampes, avec la date, le nom, la signification, les traductions français/anglais, ainsi que le peintre de l'estampe et l'année de création."
        },
        {
          property: 'og:description',
          content: "Retrouvez les 72 Saisons japonaises dans la liste des estampes, avec la date, le nom, la signification, les traductions français/anglais, ainsi que le peintre de l'estampe et l'année de création."
        }
      ]
    }
  },
  {
    path: '/fr/privacy_policy',
    name: 'privacyFR',
    component: PrivacyPolicy,
    meta: {
      title: 'Politique de confidentialité',
      metaTags: [
        {
          name: 'description',
          content: 'Politique de confidentialité'
        },
        {
          property: 'og:description',
          content: 'Politique de confidentialité'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
//  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

//  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
//  if(nearestWithTitle) {
//    document.title = nearestWithTitle.meta.title;
//  } else if(previousNearestWithMeta) {
//    document.title = previousNearestWithMeta.meta.title;
//  }

  // titleとdescriptionを設定
  var seasonData = ''
  var description = ''
  if (Object.keys(to.params).length !== 0) {
    seasonData = Store.getters.seasonList
    if (to.name === 'seasonItem') {
      for (var i = 0; i < seasonData.length; i++) {
        if (seasonData[i].furigana.toLowerCase() == to.params.season.replaceAll("-", " ")) {
          description = '二十四節気七十二候の一つである『'
            + seasonData[i].kanji + '(' + seasonData[i].kana + ')』の日付・意味・英訳・フランス語訳・浮世絵です。'
          document.title = seasonData[i].kanji + '(' + seasonData[i].kana + ') | 浮世絵で見る七十二候'
        }
      }
    } else if (to.name === 'seasonItemEN') {
      for (var j = 0; j < seasonData.length; j++) {
        if (seasonData[j].furigana.toLowerCase() == to.params.season.replaceAll("-", " ")) {
          description = '72 Seasons in Japan: '
            + seasonData[j].kanji + '(' + seasonData[j].furigana + ') and Its Date, Meaning, English/French Translations, along with Beautiful Ukiyo-e.'
          document.title = seasonData[j].kanji + '(' + seasonData[j].furigana + ') | 72 Seasons with Ukiyo-e'
        }
      }
    } else if (to.name === 'seasonItemFR') {
      for (var k = 0; k < seasonData.length; k++) {
        if (seasonData[k].furigana.toLowerCase() == to.params.season.replaceAll("-", " ")) {
          description = '72 Saisons du Japon : '
            + seasonData[k].kanji + '(' + seasonData[k].furigana + ') et leurs dates, significations, traductions français/anglais, illustrées par de magnifiques estampes.'
          document.title = seasonData[k].kanji + '(' + seasonData[k].furigana + ') | 72 Saisons du Japon'
        }
      }
    }
  } else {
    document.title = to.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      if (!tagDef[key]) {
        tag.setAttribute(key, description)
      } else {
        tag.setAttribute(key, tagDef[key])
      }
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')
    return tag
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag))
  next()
});

export default router
