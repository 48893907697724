// Styles
//import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
// 使用するアイコンのみインポートする
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { mdiHome, mdiBookOpenVariant, mdiWeatherPartlyCloudy, mdiMagnify,
  mdiOpenInNew, mdiCloseCircle, mdiChevronRight, mdiChevronLeft, mdiTranslate } from '@mdi/js'

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      home: mdiHome,
      book: mdiBookOpenVariant,
      weather: mdiWeatherPartlyCloudy,
      magnify: mdiMagnify,
      openNew: mdiOpenInNew,
      close: mdiCloseCircle,
      right: mdiChevronRight,
      left: mdiChevronLeft,
      translate: mdiTranslate,
    },
    sets: {
      mdi,
    },
  },
})
